import { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios"; // Use axios for API calls
import { backendPath } from "../Constantes";

export const usePlayerData = (url: string): any => {
	const [data, setData] = useState<any>(null);
	const isMounted = useRef(true);

	const getAPIData = useCallback(async () => {
		try {
			const response = await axios.get(backendPath + url);
			console.log(response.data);

			if (isMounted.current) {
				// Only update state if the new data is different to avoid unnecessary re-renders
				setData((prevData: any) => {
					if (JSON.stringify(prevData) !== JSON.stringify(response.data)) {
						return response.data;
					}
					return prevData;
				});
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	}, [url]);

	useEffect(() => {
		isMounted.current = true;
		getAPIData(); // Fetch data when the component mounts

		return () => {
			isMounted.current = false;
		};
	}, [getAPIData]);

	return { data };
};
