import { useState } from "react";
import { usePlayerData } from "../hooks/usePlayerDatas";
import "./../App.css";
import { canvasHeight, canvasWidth } from "../Constantes";

interface StartProps {
	musicEnabledRef: React.MutableRefObject<boolean>;
	containerRef: React.RefObject<HTMLDivElement>;
	onStart: () => void;
	onMusicToggle: (enabled: boolean) => void;
}

export const Start = ({ musicEnabledRef, onStart, onMusicToggle }: StartProps) => {
	const [hideStarter, setHideStarter] = useState<boolean>(false);
	const [enableMusic, setEnableMusic] = useState<boolean>(false);
	const handleStartClick = () => {
		setTimeout(function () {
			setHideStarter(true);
		}, 700);
		onStart(); // Call the onStart function passed from App to hide the starter container
	};

	const handleMusicToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEnableMusic(event.target.checked);
		onMusicToggle(event.target.checked); // Call the onMusicToggle function to update the music state
	};

	const handleLoadClick = () => {
		//onStart();
	};

	return (
		<>
			{!hideStarter && (
				<div id="starter-container" style={{ overflow: "auto", width: "100%", height: "100%" }}>
					<div id="btnsContainer">
						<button className="btnMagicDragon" onClick={handleStartClick} style={{ padding: "10px 20px", margin: "5px" }}>
							Start
						</button>
						<button className="btnMagicDragon" onClick={handleLoadClick} style={{ padding: "10px 20px", margin: "5px" }}>
							Load
						</button>
						<div style={{ marginTop: "10px" }}>
							<input className="cbMagicDragon" type="checkbox" id="music-checkbox" checked={enableMusic} onChange={handleMusicToggle} />
							<label htmlFor="music-checkbox" style={{ marginLeft: "5px" }}>
								Activate Music
							</label>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
