import { MapMonde } from "../classes/MapMonde";
import { Personnage } from "../classes/Personnage";

export class MoutonJaune extends Personnage {
	constructor(id: string, x: number, y: number, direction: number) {
		// Call the parent class constructor with Mouton-specific properties
		super(
			id,
			"moutonJaune.png", // Path to the Mouton's image
			"LTTP_Enemy_Hit.wav", // Sound when Mouton is hit
			"LTTP_Enemy_Kill.wav", // Sound when Mouton is killed
			x,
			y,
			direction,
			true, // blocked
			4, // largeur (width)
			8, // hauteur (height)
			180, // hp_max (maximum health)
			180, // hp (current health)
			30, // attaque (attack power)
			110, // experience_donnee (experience given upon defeat)
			4, // duree_animation (animation duration)
			16, // duree_deplacement (movement duration)
			2, // duree_animationAT (attack animation duration)
			10, // duree_deplacementAT (attack movement duration)
			undefined, // sonAttaquer (attack sound, if any)
			true // deplacerPendantAttaque (can move while attacking)
		);
	}

	// You can add Mouton-specific methods or override methods from Personnage here if needed
	// For example, a special behavior or interaction can be defined

	// Example of overriding a method to customize Mouton behavior
	attaquer(direction: number, map: MapMonde): boolean {
		// Custom attack behavior specific to Mouton can be added here
		console.log("Mouton is attacking!");
		return super.attaquer(direction, map); // Call the parent method for basic attack logic
	}

	// Example of a Mouton-specific method
	beSheared(): void {
		// Example of a Mouton-specific action, such as being sheared
		console.log("Mouton has been sheared!");
	}
}
