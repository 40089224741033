export class Tileset {
	path_img: string;
	image: HTMLImageElement;
	largeur: number;

	constructor(path_img: string) {
		// Chargement de l'image dans l'attribut image
		this.path_img = path_img;
		this.image = new Image();
		this.largeur = 1;
		this.image.onload = () => {
			if (!this.image.complete) throw new Error('Erreur de chargement du tileset nommé "' + this.path_img + '".');

			// Largeur du tileset en tiles
			this.largeur = this.image.width / 32;
		};
		this.image.src = "/assets/tilesets/" + path_img;
	}

	// Méthode de dessin du tile numéro "numero" dans le contexte 2D "context" aux coordonnées x et y
	dessinerTile(numero: number, context: CanvasRenderingContext2D, dotted: boolean, xDestination: number, yDestination: number): void {
		var xSourceEnTiles = numero % this.largeur;
		if (xSourceEnTiles === 0) xSourceEnTiles = this.largeur;
		var ySourceEnTiles = Math.ceil(numero / this.largeur);

		var xSource = (xSourceEnTiles - 1) * 32;
		var ySource = (ySourceEnTiles - 1) * 32;

		context.drawImage(this.image, xSource, ySource, 32, 32, xDestination, yDestination, 32, 32);
		// If the dotted flag is true, draw a dotted border around the tile
		if (dotted) {
			context.fillStyle = "rgba(128, 128, 128, 0.3)"; // Semi-transparent grey color
			context.fillRect(xDestination, yDestination, 32, 32);
			context.setLineDash([2, 2]); // Set line dash: [dashLength, spaceLength]
			context.strokeStyle = "black"; // Set the color for the dotted line
			context.lineWidth = 1; // Set the line width
			context.strokeRect(xDestination, yDestination, 32, 32); // Draw the border
			context.setLineDash([]); // Reset line dash to solid lines
		}
	}
}
