import { useRef } from "react";
import { DIRECTION, Personnage } from "./Personnage";
import { Tileset } from "./Tileset";
import { Mouton } from "../PNJ/Mouton";
import { MoutonJaune } from "../PNJ/MoutonJaune";

export interface PNJ {
	className: string;
	x: number;
	y: number;
	direction: number;
}

export class MapMonde {
	nom: string;
	x: number;
	y: number;
	xMonde: number;
	yMonde: number;
	tileset: Tileset;
	imageMap: HTMLImageElement;
	musique: HTMLAudioElement;
	musicEnabled: boolean;
	musiqueStarted: boolean;
	terrain: number[][];
	PNJs: PNJ[];
	personnages: Personnage[];
	personnagesComplet: Personnage[];

	constructor(nom: string, musique: string, musicEnabled: boolean, x: number, y: number) {
		// Création de l'objet XmlHttpRequest
		const xhr = this.getXMLHttpRequest();

		// Chargement du fichier
		xhr.open("GET", "assets/maps/" + nom + ".json", false);
		xhr.send(null);
		if (xhr.readyState !== 4 || (xhr.status !== 200 && xhr.status !== 0))
			// Code == 0 en local
			throw new Error('Impossible de charger la carte nommée "' + nom + '" (code HTTP : ' + xhr.status + ").");
		const mapJsonData = xhr.responseText;

		// Analyse des données
		const mapData = JSON.parse(mapJsonData);
		this.nom = nom;
		this.x = x % 4;
		this.y = y % 4;
		this.xMonde = Math.floor(x / 4);
		this.yMonde = Math.floor(y / 4);
		this.tileset = new Tileset(mapData.tileset);
		this.imageMap = new Image();
		this.imageMap.src = "assets/maps/images/" + nom + ".png";
		this.musique = new Audio("assets/sons/" + musique + ".wav");
		this.musicEnabled = musicEnabled;
		this.musiqueStarted = false;
		this.terrain = mapData.terrain;

		// Liste des personnages présents sur le terrain.
		this.PNJs = mapData.enemis;
		this.personnages = [];
		this.personnagesComplet = [];

		for (let i = 0; i < this.PNJs.length; i++) {
			let pnj = null;
			switch (this.PNJs[i].className) {
				case "Mouton":
					pnj = new Mouton("enemiMouton" + i + nom, this.PNJs[i].x, this.PNJs[i].y, this.PNJs[i].direction);
					break;
				case "MoutonJaune":
					pnj = new MoutonJaune("enemiMoutonJaune" + i + nom, this.PNJs[i].x, this.PNJs[i].y, this.PNJs[i].direction);
					break;
				default:
			}
			if (pnj) this.addPersonnage(pnj);
		}
	}

	// Pour récupérer la taille (en tiles) de la carte
	getHauteur(): number {
		return this.terrain.length;
	}

	getLargeur(): number {
		return this.terrain[0].length;
	}

	addPersonnage(perso: Personnage, persistant?: boolean): void {
		this.personnages.push(perso);
		if (persistant) {
			this.personnagesComplet.push(perso);
		}
	}

	removePersonnage(perso: Personnage): void {
		const index = this.personnages.indexOf(perso);
		if (index > -1) {
			this.personnages.splice(index, 1);
		}
	}

	dessinerMap(context: CanvasRenderingContext2D, afficher_monde: boolean): void {
		//console.log(this.musicEnabled);
		if (this.musicEnabled && !this.musiqueStarted && this.personnages.find((joueur) => joueur.id === "joueur")?.hasMoved) {
			this.musique.volume = 0.1;
			this.musique.loop = true;
			this.musique.play();
			this.musiqueStarted = true;
		}
		if (!afficher_monde) {
			// Draw terrain
			for (let i = 0; i < this.terrain.length; i++) {
				let ligne = this.terrain[i];
				let y = i * 32;
				for (let j = 0; j < ligne.length; j++) {
					this.tileset.dessinerTile(ligne[j], context, false, j * 32, y);
				}
			}

			// Find main character's position
			let positionTableauPersoPrincipal: number | undefined;
			for (let i = 0; i < this.personnages.length; i++) {
				if (this.personnages[i].id == "joueur") {
					positionTableauPersoPrincipal = i;
					break;
				}
			}

			// Reorder characters for layered rendering
			if (positionTableauPersoPrincipal !== undefined) {
				for (let i = 0; i < this.personnages.length; i++) {
					let tableauTemp: Personnage;
					if (this.personnages[positionTableauPersoPrincipal].y > this.personnages[i].y && positionTableauPersoPrincipal < i) {
						tableauTemp = this.personnages[i];
						this.personnages[i] = this.personnages[positionTableauPersoPrincipal];
						this.personnages[positionTableauPersoPrincipal] = tableauTemp;
					} else if (this.personnages[positionTableauPersoPrincipal].y < this.personnages[i].y && positionTableauPersoPrincipal > i) {
						tableauTemp = this.personnages[i];
						this.personnages[i] = this.personnages[positionTableauPersoPrincipal];
						this.personnages[positionTableauPersoPrincipal] = tableauTemp;
					}
				}
			}

			// Draw characters
			for (let i = 0; i < this.personnages.length; i++) {
				this.personnages[i].dessinerPersonnage(context);
			}
		} else {
			/*
            // Displaying the world map
            const tableauMapsDuMonde: MapMonde[] = []; // Replace YourMapType with the actual type
            for (let a = 0; a < tableauDesMaps.length; a++) {
                if (mapActuelle.xMonde === tableauDesMaps[a].xMonde && mapActuelle.yMonde === tableauDesMaps[a].yMonde) {
                    tableauMapsDuMonde.push(tableauDesMaps[a]);
                }
            }
        
            for (let y = 0; y < 4; y++) {
                for (let x = 0; x < 4; x++) {
                    let mapAAfficher: YourMapType | null = null;
                    for (let i = 0; i < tableauMapsDuMonde.length; i++) {
                        if (tableauMapsDuMonde[i].x === x && tableauMapsDuMonde[i].y === y && joueur.mapsVisitees.includes(tableauMapsDuMonde[i].nom)) {
                            mapAAfficher = tableauMapsDuMonde[i];
                        }
                    }
                    if (mapAAfficher !== null) {
                        context.drawImage(mapAAfficher.imageMap, 0, 0, 736, 576, mapAAfficher.x * 184, mapAAfficher.y * 144, 184, 144);
                    } else {
                        context.fillStyle = "black";
                        context.fillRect(x * 184, y * 144, 184, 144);
                    }
                }
            }
            // Draw the border around the current map
            context.strokeStyle = "white";
            context.strokeRect(mapActuelle.x * 184, mapActuelle.y * 144, 184, 144);*/
		}
	}

	deplacerEnemi(mapActuelle: MapMonde, personnages: Personnage[]): void {
		const joueur = personnages.find((p) => p.id === "joueur");
		if (joueur && joueur.hasMoved) {
			const joueurPosX = joueur.x;
			const joueurPosY = joueur.y;

			for (let i = 0; i < personnages.length; i++) {
				const ennemi = personnages[i];
				if (ennemi.id !== "joueur") {
					const distanceX = joueurPosX - ennemi.x;
					const distanceY = joueurPosY - ennemi.y;
					const absDistanceX = Math.abs(distanceX);
					const absDistanceY = Math.abs(distanceY);
					const isAdjacent = absDistanceX + absDistanceY === 1;

					// Check if the enemy is within 5 tiles of the player
					const withinRange = absDistanceX < 5 && absDistanceY < 5;

					if (withinRange) {
						if (isAdjacent) {
							// 2/3 chance to move away, 1/3 chance to attack
							const moveAwayChance = Math.random();
							if (moveAwayChance < 0.67) {
								// Move away from the player
								if (absDistanceX > absDistanceY) {
									// Move horizontally away from the player
									if (distanceX > 0) {
										ennemi.deplacer(DIRECTION.GAUCHE, mapActuelle); // Move left
									} else {
										ennemi.deplacer(DIRECTION.DROITE, mapActuelle); // Move right
									}
								} else {
									// Move vertically away from the player
									if (distanceY > 0) {
										ennemi.deplacer(DIRECTION.HAUT, mapActuelle); // Move up
									} else {
										ennemi.deplacer(DIRECTION.BAS, mapActuelle); // Move down
									}
								}
							} else {
								// 1/3 chance to attack
								ennemi.attaquer(ennemi.direction, mapActuelle);
							}
						} else {
							// Move towards the player based on relative positions
							if (absDistanceX > absDistanceY) {
								// Move horizontally towards the player
								if (distanceX > 0) {
									ennemi.deplacer(DIRECTION.DROITE, mapActuelle); // Move right
								} else {
									ennemi.deplacer(DIRECTION.GAUCHE, mapActuelle); // Move left
								}
							} else {
								// Move vertically towards the player
								if (distanceY > 0) {
									ennemi.deplacer(DIRECTION.BAS, mapActuelle); // Move down
								} else {
									ennemi.deplacer(DIRECTION.HAUT, mapActuelle); // Move up
								}
							}
						}
					}

					// Occasionally perform a random movement
					const randomValue = Math.random();
					if (randomValue < 0.5) {
						// 10% chance for random move
						const randomDirection = Math.floor(Math.random() * 4);
						switch (randomDirection) {
							case 0:
								ennemi.deplacer(DIRECTION.GAUCHE, mapActuelle);
								break;
							case 1:
								ennemi.deplacer(DIRECTION.DROITE, mapActuelle);
								break;
							case 2:
								ennemi.deplacer(DIRECTION.HAUT, mapActuelle);
								break;
							case 3:
								ennemi.deplacer(DIRECTION.BAS, mapActuelle);
								break;
						}
					}
				}
			}
		}
	}

	faireDeplacerEnemis(choisir?: boolean, interval?: number): void {
		const deplacer_enemis = setInterval(() => {
			this.deplacerEnemi(this, this.personnages);
		}, interval || 350);
	}

	private getXMLHttpRequest(): XMLHttpRequest {
		if (window.XMLHttpRequest) {
			// Code for modern browsers
			return new XMLHttpRequest();
		} else {
			// Code for old IE browsers
			return new ActiveXObject("Microsoft.XMLHTTP");
		}
	}
}
