import { MapMonde } from "./MapMonde";

export interface Context {
	font: string;
	fillStyle: string;
	strokeStyle: string;
	fillRect: (x: number, y: number, width: number, height: number) => void;
	strokeRect: (x: number, y: number, width: number, height: number) => void;
	fillText: (text: string, x: number, y: number) => void;
	strokeText: (text: string, x: number, y: number) => void;
}

export interface Direction {
	BAS: number;
	GAUCHE: number;
	DROITE: number;
	HAUT: number;
}

export const DIRECTION: Direction = {
	BAS: 0,
	GAUCHE: 1,
	DROITE: 2,
	HAUT: 3,
};

export const TILE_BLOQUANTES: number[] = [21, 47, 46, 48, 39, 35, 41, 37, 33, 38, 34, 36, 40, 44, 45, 49, 50, 51, 52, 43];

export class Personnage {
	id: string;
	path_img: string;
	x: number; // (en cases)
	y: number; // (en cases)
	hp_max: number;
	hp: number;
	defense: number;
	mp_max: number;
	mp: number;
	experience_next_lvl: number;
	experience: number;
	experience_donnee: number;
	niveau: number;
	attaque: number;
	hasMoved: boolean;
	direction: number;
	blocked: boolean;
	largeur: number;
	largeurMem: number;
	hauteur: number;
	hauteurMem: number;
	etatAnimation: number;
	etatDeplacer: boolean;
	etatDeplacerLong: boolean;
	etatAttaquer: boolean;
	deplacerPendantAttaque: boolean;
	visible: boolean;
	duree_animation: number;
	duree_deplacement: number;
	duree_animationAT: number;
	duree_deplacementAT: number;
	sonAttaquer: string | null;
	sonEtreAttaque: HTMLAudioElement;
	sonMort: HTMLAudioElement;
	mapsVisitees: string[];
	nom_map: string;
	image: HTMLImageElement;

	constructor(id: string, path_img: string, sonEtreAttaque: string, sonMort: string, x: number, y: number, direction: number, blocked: boolean, largeur: number, hauteur: number, HPMax: number, HP: number, attaque: number, experience_donnee: number, duree_animation: number, duree_deplacement: number, duree_animationAT: number, duree_deplacementAT: number, sonAttaquer?: string, deplacerPendantAttaque?: boolean) {
		this.id = id;
		this.path_img = path_img;
		this.x = x;
		this.y = y;
		this.hp_max = HPMax;
		this.hp = HP;
		this.defense = 5;
		this.mp_max = 20;
		this.mp = 20;
		this.experience_next_lvl = 100;
		this.experience = 0;
		this.experience_donnee = experience_donnee;
		this.niveau = 1;
		this.attaque = attaque;
		this.hasMoved = false;
		this.direction = direction;
		this.blocked = false;
		this.largeur = largeur;
		this.largeurMem = largeur;
		this.hauteur = hauteur;
		this.hauteurMem = hauteur;
		this.etatAnimation = -1;
		this.etatDeplacer = false;
		this.etatDeplacerLong = false;
		this.etatAttaquer = false;
		this.deplacerPendantAttaque = deplacerPendantAttaque || false;
		this.visible = true;
		this.duree_animation = duree_animation;
		this.duree_deplacement = duree_deplacement;
		this.duree_animationAT = duree_animationAT;
		this.duree_deplacementAT = duree_deplacementAT;
		this.sonAttaquer = sonAttaquer || null;
		this.sonEtreAttaque = new Audio("assets/sons/" + sonEtreAttaque);
		this.sonMort = new Audio("assets/sons/" + sonMort);
		this.mapsVisitees = [];
		this.nom_map = "";

		// Chargement de l'image dans l'attribut image
		this.image = new Image();
		this.image.onload = () => {
			if (!this.image.complete) throw new Error('Erreur de chargement du sprite nommé "' + path_img + '".');

			// Taille du personnage
			this.largeur = this.image.width / largeur;
			this.hauteur = this.image.height / hauteur;
			//    console.log(this.referenceDuPerso.largeur);
		};
		this.image.src = "/assets/sprites/" + path_img;
	}

	/*dessinerHP(context: CanvasRenderingContext2D, decalageX: number, decalageY: number, decalageYLink: number): void {
		context.font = "bold 20px Verdana";
		//if (this.id === "joueur") context.fillStyle = "green";
		//else context.fillStyle = "#CF0000";
		context.fillStyle = "green";
		context.strokeStyle = "black";
		context.fillRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur * (this.hp / this.hp_max), -10);
		context.strokeRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur, -10);
	}*/
	dessinerHPBar(context: CanvasRenderingContext2D, decalageX: number, decalageY: number, decalageYLink: number, showTxt: boolean): void {
		// Determine the HP percentage
		context.beginPath();
		context.roundRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur, -10, [40]);
		context.stroke();
		context.fillStyle = "#a5a5a5";
		context.beginPath();
		context.roundRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur, -10, [40]);
		context.fill();

		const hpPercentage = this.hp / this.hp_max;
		const fullHPRounded = this.hp === this.hp_max ? 40 : 0;
		// Set the color based on the HP percentage
		if (hpPercentage <= 0.35) {
			context.fillStyle = "red"; // Red if HP is 25% or less
		} else if (hpPercentage <= 0.7) {
			context.fillStyle = "#f76820"; // Orange if HP is 50% or less
		} else {
			context.fillStyle = "green"; // Green otherwise
		}

		context.beginPath();
		context.roundRect(
			this.x * 32 - this.largeur / 4 + decalageX,
			this.y * 32 - this.hauteur + decalageYLink + decalageY,
			this.largeur * hpPercentage, // Width based on HP percentage
			-10,
			[40, fullHPRounded, fullHPRounded, 40]
		);
		context.strokeStyle = "black";
		context.stroke();
		//context.fillStyle = "#515151";
		context.beginPath();
		context.roundRect(
			this.x * 32 - this.largeur / 4 + decalageX,
			this.y * 32 - this.hauteur + decalageYLink + decalageY,
			this.largeur * hpPercentage, // Width based on HP percentage
			-10,
			[40, fullHPRounded, fullHPRounded, 40]
		);
		context.fill();
		if (showTxt) {
			context.font = "bold 12px verdana";
			context.fillStyle = "white";
			context.strokeStyle = "black";
			/*
		context.fillText("XP  " + this.experience + "/" + this.experience_next_lvl, this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);
		context.strokeText("XP  " + this.experience + "/" + this.experience_next_lvl, this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);*/

			context.fillText("HP", this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY);
			context.strokeText("HP", this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY);
		}
		/*
		context.strokeStyle = "black";
		// Draw the filled portion of the HP bar
		context.fillRect(
			this.x * 32 - this.largeur / 4 + decalageX,
			this.y * 32 - this.hauteur + decalageYLink + decalageY,
			this.largeur * hpPercentage, // Width based on HP percentage
			-10
		);

		// Draw the outline of the HP bar
		context.strokeRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur, -10);*/
	}

	dessinerXPBar(context: CanvasRenderingContext2D, decalageX: number, decalageY: number, decalageYLink: number, showTxt: boolean): void {
		context.beginPath();
		context.roundRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY + 2, this.largeur, 8, [40]);
		context.stroke();
		context.fillStyle = "#9e9e9e";
		context.beginPath();
		context.roundRect(this.x * 32 - this.largeur / 4 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY + 2, this.largeur, 8, [40]);
		context.fill();

		const xpPercentage = this.experience / this.experience_next_lvl;
		context.beginPath();
		context.fillStyle = "#45d3e1";
		context.strokeStyle = "black";
		context.roundRect(this.x * 32 - this.largeur / 4 + decalageX + 2, this.y * 32 - this.hauteur + decalageYLink + decalageY + 3, this.largeur * xpPercentage, 5.5, [40, 0, 0, 40]);
		context.fill();
		if (showTxt) {
			context.font = "bold 12px verdana";
			context.fillStyle = "white";
			context.strokeStyle = "black";
			/*
		context.fillText("XP  " + this.experience + "/" + this.experience_next_lvl, this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);
		context.strokeText("XP  " + this.experience + "/" + this.experience_next_lvl, this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);*/
			context.fillText("XP", this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);
			context.strokeText("XP", this.x * 32 - this.largeur / 4 + decalageX - 22, this.y * 32 - this.hauteur + decalageYLink + decalageY + 12);
		}
	}

	dessinerPersonnage(context: CanvasRenderingContext2D): void {
		let duree_animation = this.etatAttaquer ? this.duree_animationAT : this.duree_animation;
		let duree_deplacement = this.etatAttaquer ? this.duree_deplacementAT : this.duree_deplacement;
		let frame = 0;
		let decalageX = 0,
			decalageY = 0;
		let departFrameAttaque = 0;
		let decalageYLink = this.id === "joueur" ? 35 : 24;
		if (this.etatAnimation >= duree_deplacement) {
			this.etatAnimation = -1;
			this.etatAttaquer = false;
			this.etatDeplacer = false;
		} else if (this.etatAnimation >= 0) {
			frame = Math.floor(this.etatAnimation / duree_animation);
			if (frame > this.largeurMem - 1) {
				frame %= this.largeurMem;
			}
			//console.log(frame);
			let pixelsAParcourir = 32 - 32 * (this.etatAnimation / duree_deplacement);
			//console.log(pixelsAParcourir);
			if ((this.deplacerPendantAttaque && this.etatDeplacer) || this.etatDeplacer) {
				switch (this.direction) {
					case DIRECTION.HAUT:
						decalageY = pixelsAParcourir;
						break;
					case DIRECTION.BAS:
						decalageY = -pixelsAParcourir;
						break;
					case DIRECTION.GAUCHE:
						decalageX = pixelsAParcourir;
						break;
					case DIRECTION.DROITE:
						decalageX = -pixelsAParcourir;
						break;
				}
			}
			if (this.etatAttaquer) {
				departFrameAttaque = 4;
			}
			this.etatAnimation++;
		}

		if (this.visible) {
			//console.log(this.largeur * frame, (this.direction + departFrameAttaque) * this.hauteur,)
			context.drawImage(this.image, this.largeur * frame, (this.direction + departFrameAttaque) * this.hauteur, this.largeur, this.hauteur, this.x * 32 - this.largeur / 2 + 16 + decalageX, this.y * 32 - this.hauteur + decalageYLink + decalageY, this.largeur, this.hauteur);
			if (this.hp > 0) {
				const showHPTxt = false;
				const showXPTxt = false;
				const isJoueur = this.id === "joueur";
				this.dessinerHPBar(context, decalageX, decalageY, decalageYLink, showHPTxt);
				if (isJoueur) this.dessinerXPBar(context, decalageX, decalageY, decalageYLink, showXPTxt);
			}
		} else {
			this.visible = true;
		}
	}

	getCoordonneesAdjacentes(direction: number): { x: number; y: number } {
		let coord = { x: this.x, y: this.y };
		switch (direction) {
			case DIRECTION.BAS:
				coord.y++;
				break;
			case DIRECTION.GAUCHE:
				coord.x--;
				break;
			case DIRECTION.DROITE:
				coord.x++;
				break;
			case DIRECTION.HAUT:
				coord.y--;
				break;
		}
		return coord;
	}

	deplacer(direction: number, map: MapMonde): boolean {
		if (this.etatAnimation >= 0) {
			//console.log(this.etatAnimation);
			return false; // Cannot move if a movement is already in progress
		}

		this.direction = direction; // Change the character's direction

		const prochaineCase = this.getCoordonneesAdjacentes(direction); // Get the next position
		for (let i = 0; i < map.personnages.length; i++) {
			//console.log(prochaineCase);
			const blocking = map.terrain[prochaineCase.y][prochaineCase.x];
			const isTileBlocking = TILE_BLOQUANTES.includes(blocking); // Assuming TILE_BLOQUANTES is an array of blocking tiles

			if (prochaineCase.x < 0 || prochaineCase.y < 0 || prochaineCase.x >= map.getLargeur() || prochaineCase.y >= map.getHauteur() || (prochaineCase.x === map.personnages[i].x && prochaineCase.y === map.personnages[i].y) || isTileBlocking) {
				if (!(this.path_img === "crabe.png" && blocking === 21)) {
					this.blocked = true;
					return false; // Return false if the next position is invalid or blocked
				}
			}
		}
		this.blocked = false;
		this.etatAnimation = 1;
		this.etatDeplacer = true;
		this.etatAttaquer = false;
		this.x = prochaineCase.x;
		this.y = prochaineCase.y;
		//console.log(this);
		return true;
	}

	attaquer(direction: number, map: MapMonde): boolean {
		if (!this.deplacerPendantAttaque && this.etatAnimation >= 0) {
			return false;
		}

		if (this.sonAttaquer && !this.etatAttaquer) {
			let jouerSonAttaquer = new Audio("/assets/sons/" + this.sonAttaquer);
			jouerSonAttaquer.play();
		}

		this.etatAnimation = 1;
		this.etatAttaquer = true;

		let prochaineCase = this.getCoordonneesAdjacentes(direction);

		for (let i = 0; i < map.personnages.length; i++) {
			let perso = map.personnages[i];
			if (prochaineCase.x === perso.x && prochaineCase.y === perso.y) {
				perso.prendreCoup(this, map);
			}
		}

		return true;
	}

	prendreCoup(attaquant: Personnage, map: MapMonde): void {
		let hpperdus = attaquant.attaque - this.defense <= 0 ? 1 : attaquant.attaque - this.defense;
		this.hp -= hpperdus;
		this.etatAnimation = -1;

		if (this.deplacerPendantAttaque) {
			//this.deplacer(attaquant.direction, map);
		}

		if (this.sonEtreAttaque) {
			this.sonEtreAttaque.play();
		}

		if (this.hp <= 0) {
			if (this.sonMort) {
				this.sonMort.play();
			}

			const index = map.personnages.indexOf(this);
			if (index > -1) {
				map.personnages.splice(index, 1); // Remove the defeated character from the map
			}
			if (attaquant.id === "joueur") {
				console.log(this.experience_donnee);
				attaquant.recevoirExperience(this.experience_donnee);
			}

			if (this.id === "joueur") {
				setTimeout(function () {
					window.location.href = window.location.href; // Reload the page if the player dies
				}, 2000);
				console.log(this.experience_donnee);
				attaquant.recevoirExperience(this.experience_donnee);
			}
		}

		this.visible = false; // Set the character as not visible after taking damage
	}

	recevoirExperience(experience: number) {
		this.experience += experience;
		while (this.experience >= this.experience_next_lvl) {
			this.experience = Math.abs(this.experience_next_lvl - this.experience);
			this.gagnerNiveau();
		}
	}

	gagnerNiveau() {
		this.niveau++;
		let sonGagnerNiveau = new Audio("/assets/sons/LTTP_Mushroom.wav");
		sonGagnerNiveau.play();
		this.attaque += Math.floor((this.attaque * 7) / 100);
		this.hp_max += Math.floor((this.hp_max * 7) / 100);
		this.hp = this.hp_max;
		this.mp_max += 10;
		this.mp = this.mp_max;
		this.defense += 2;
		this.experience_next_lvl += Math.floor((this.experience_next_lvl * 15) / 100);
	}

	// The loadStats and saveStats methods appear to involve AJAX requests.
	// AJAX operations in TypeScript are typically handled using the Fetch API or a library like Axios.
	// Here, I'll provide a basic structure for these methods.
	/*
    loadStats(): void {
        // Fetch API example
        fetch("functions/chargerJoueurAjax.php")
            .then(response => response.json())
            .then(data => {
                // Your code to handle the data
            });
    }

    saveStats(): void {
        // Fetch API example for a POST request
        fetch("functions/saveJoueurAjax.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.prepareSaveData())
        })
        .then(response => response.json())
        .then(data => {
            // Handle the response
        });
    }   */
}
