const hostname = window.location.hostname;
const canvasWidth = 736;
const canvasHeight = 576;
let backendPath = "";

if (hostname === "localhost") {
	backendPath = "http://dev.magicdragon2024.local/backend/"; // Relative URL for development
} else {
	backendPath = "/backend/"; // Same for production if on the same server
}

export { backendPath, canvasWidth, canvasHeight };
