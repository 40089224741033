import { DIRECTION, Personnage } from "../classes/Personnage";
import { MapMonde } from "../classes/MapMonde";
import { useEffect, useRef, useMemo, useState } from "react";
import { Mouton } from "../PNJ/Mouton";
import { canvasHeight, canvasWidth } from "../Constantes";

interface JeuProps {
	musicEnabled: boolean;
	containerRef: React.RefObject<HTMLDivElement>;
}

interface KeyActions {
	[key: string]: () => void;
}

export function Jeu({ musicEnabled, containerRef }: JeuProps) {
	console.log(musicEnabled);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const gameLoopRef = useRef<NodeJS.Timer | null>(null);
	const movePlayerToPositionRef = useRef<NodeJS.Timeout | null>(null);
	const doubleTapDetectedRef = useRef(false); // Ref to track double-tap detection

	const mapActuelle = useMemo(() => new MapMonde("map1", "LTTP_Overworld", musicEnabled, 11, 10), []);
	const joueur = useMemo(() => new Personnage("joueur", "joueur.png", "LTTP_Link_Hurt.wav", "LTTP_Link_Dying.wav", 10, 7, DIRECTION.BAS, false, 6, 8, 100, 100, 30, 0, 1.9, 5, 2, 12, "LTTP_Sword1.wav", false), []);
	mapActuelle.addPersonnage(joueur);
	//const mouton1map1 = new Mouton("enemi_mouton1map1", 8, 12, DIRECTION.GAUCHE);
	//mapActuelle.addPersonnage(mouton1map1, true);
	mapActuelle.faireDeplacerEnemis();

	const keysPressed = useRef<{ [key: string]: boolean }>({}).current;

	const keyActions: KeyActions = {
		arrowleft: () => joueur.deplacer(DIRECTION.GAUCHE, mapActuelle),
		arrowup: () => joueur.deplacer(DIRECTION.HAUT, mapActuelle),
		arrowright: () => joueur.deplacer(DIRECTION.DROITE, mapActuelle),
		arrowdown: () => joueur.deplacer(DIRECTION.BAS, mapActuelle),
		q: () => joueur.deplacer(DIRECTION.GAUCHE, mapActuelle),
		z: () => joueur.deplacer(DIRECTION.HAUT, mapActuelle),
		d: () => joueur.deplacer(DIRECTION.DROITE, mapActuelle),
		s: () => joueur.deplacer(DIRECTION.BAS, mapActuelle),
		enter: () => joueur.attaquer(joueur.direction, mapActuelle),
		" ": () => joueur.attaquer(joueur.direction, mapActuelle),
	};

	const updateMovement = () => {
		for (const key in keysPressed) {
			if (keysPressed[key] && keyActions[key]) {
				keyActions[key]();
			}
		}
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		joueur.hasMoved = true;
		clearInterval(movePlayerToPositionRef.current!);
		keysPressed[event.key.toLowerCase()] = true;
	};

	const handleKeyUp = (event: KeyboardEvent) => {
		keysPressed[event.key.toLowerCase()] = false;
	};

	const getMousePos = (canvas: HTMLCanvasElement, event: MouseEvent) => {
		var rect = canvas.getBoundingClientRect();
		return {
			x: Math.floor((event.clientX - rect.left) / 32),
			y: Math.floor((event.clientY - rect.top) / 32),
		};
	};

	const movePlayerAtPosition = (playerPosX: number, playerPosY: number, PosToGoX: number, PosToGoY: number) => {
		joueur.hasMoved = true;
		if (playerPosX > PosToGoX) {
			joueur.deplacer(DIRECTION.GAUCHE, mapActuelle);
		} else if (playerPosX < PosToGoX) {
			joueur.deplacer(DIRECTION.DROITE, mapActuelle);
		}

		if (playerPosY > PosToGoY) {
			joueur.deplacer(DIRECTION.HAUT, mapActuelle);
		} else if (playerPosY < PosToGoY) {
			joueur.deplacer(DIRECTION.BAS, mapActuelle);
		}
	};

	const getDirectionForMoving = (event: MouseEvent, canvas: HTMLCanvasElement) => {
		if (doubleTapDetectedRef.current) {
			// Cancel mousedown action if a double-tap was detedcted
			doubleTapDetectedRef.current = false;
			return;
		}

		if (movePlayerToPositionRef.current) {
			clearInterval(movePlayerToPositionRef.current);
			movePlayerToPositionRef.current = null;
		}

		joueur.etatDeplacerLong = true;
		const mousePos = getMousePos(canvas, event);

		movePlayerToPositionRef.current = setInterval(() => {
			movePlayerAtPosition(joueur.x, joueur.y, mousePos.x, mousePos.y);
			if (containerRef.current) centerPlayer(containerRef.current);
			if ((joueur.x === mousePos.x && joueur.y === mousePos.y) || joueur.blocked) {
				clearInterval(movePlayerToPositionRef.current!);
				movePlayerToPositionRef.current = null;
				joueur.etatDeplacerLong = false;
			}
		}, 50);
	};

	function centerPlayer(container: HTMLDivElement) {
		const containerRect = container.getBoundingClientRect();
		const playerCenterX = joueur.x * 32;
		const playerCenterY = joueur.y * 32;
		const scrollSpeed = 115;

		const scrollLeft = playerCenterX - containerRect.width / 2;
		const scrollTop = playerCenterY - containerRect.height / 2;

		smoothScrollTo(container, scrollLeft, scrollTop, scrollSpeed);
	}

	function smoothScrollTo(element: HTMLElement, targetLeft: number, targetTop: number, duration: number) {
		const startLeft = element.scrollLeft;
		const startTop = element.scrollTop;
		const distanceLeft = targetLeft - startLeft;
		const distanceTop = targetTop - startTop;
		const startTime = performance.now();

		function scrollStep(currentTime: number) {
			const elapsed = currentTime - startTime;
			const progress = Math.min(elapsed / duration, 1);
			const easeInOutQuad = progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress;

			element.scrollLeft = startLeft + distanceLeft * easeInOutQuad;
			element.scrollTop = startTop + distanceTop * easeInOutQuad;

			if (elapsed < duration) {
				requestAnimationFrame(scrollStep);
			}
		}
		requestAnimationFrame(scrollStep);
	}

	const setupDoubleTapDetection = (canvas: HTMLCanvasElement, timeout: number = 350) => {
		let lastTap = 0;
		let tapTimeout: NodeJS.Timeout | null = null;

		const onTouchStart = (event: TouchEvent) => {
			const currentTime = new Date().getTime();
			const tapLength = currentTime - lastTap;

			if (tapLength < timeout && tapLength > 0) {
				// Detected a double tap
				if (tapTimeout) {
					clearTimeout(tapTimeout);
				}
				doubleTapDetectedRef.current = true; // Set the double-tap detected flag

				clearInterval(movePlayerToPositionRef.current!);
				movePlayerToPositionRef.current = null;
				joueur.etatDeplacerLong = false;

				joueur.attaquer(joueur.direction, mapActuelle);
			} else {
				// Detected a single tap, set timeout to wait for possible second tap
				tapTimeout = setTimeout(() => {
					clearTimeout(tapTimeout as NodeJS.Timeout);
					tapTimeout = null;
				}, timeout);
			}

			lastTap = currentTime;
		};

		canvas.addEventListener("touchstart", onTouchStart, false);

		return () => {
			canvas.removeEventListener("touchstart", onTouchStart);
			if (tapTimeout) {
				clearTimeout(tapTimeout);
			}
		};
	};

	useEffect(() => {
		const canvas = canvasRef.current;

		if (canvas) {
			const ctx = canvas.getContext("2d");

			if (!gameLoopRef.current) {
				gameLoopRef.current = setInterval(() => {
					if (ctx) {
						mapActuelle.dessinerMap(ctx, false);
						if (containerRef.current) centerPlayer(containerRef.current);
						updateMovement();
					}
				}, 35);
			}

			document.addEventListener("keydown", handleKeyDown);
			document.addEventListener("keyup", handleKeyUp);

			canvas.addEventListener("mousedown", (event) => getDirectionForMoving(event, canvas), false);
			console.log(canvas.offsetLeft + joueur.x * 32, canvas.offsetTop + joueur.y * 32);
			canvas.dispatchEvent(
				new MouseEvent(
					"mousedown", // or "mousedown" if the canvas listens for such an event
					{
						clientX: canvas.getBoundingClientRect().left + joueur.x * 32 + 1,
						clientY: canvas.getBoundingClientRect().top + joueur.y * 32 + 1,
						bubbles: true, // Enable event propagation
					}
				)
			);
			const cleanupDoubleTap = setupDoubleTapDetection(canvas);

			return () => {
				if (gameLoopRef.current) {
					clearInterval(gameLoopRef.current);
					gameLoopRef.current = null;
				}
				document.removeEventListener("keydown", handleKeyDown);
				document.removeEventListener("keyup", handleKeyUp);
				cleanupDoubleTap();
			};
		}
	}, []);

	return (
		<>
			<canvas ref={canvasRef} id="canvas" width={canvasWidth} height={canvasHeight}></canvas>
		</>
	);
}
