import { useRef, useState } from "react";
import "./App.css";
import { Jeu } from "./components/jeu";
import { Menu } from "./components/menu";
import { Start } from "./components/start";

function App() {
	const canvaswidth = 736;
	const canvasheight = 576;
	const containerRef = useRef<HTMLDivElement>(null);
	const musicEnabledRef = useRef<boolean>(false);
	//let showStarterContainerRef = useRef<boolean>(true);
	const [showStarterContaine, setShowStarterContainer] = useState<boolean>(true);

	// Function to update the music state
	const handleMusicToggle = (enabled: boolean) => {
		console.log(enabled);
		musicEnabledRef.current = enabled;
	};

	// Function to update the starter container state
	const handleStartGame = () => {
		const startSound = new Audio("assets/sons/LTTP_SaveQuit.wav");
		startSound.volume = 0.5;
		startSound.play();
		setShowStarterContainer(false);
	};

	return (
		<div className="App">
			<header className="App-header">
				<Menu />
				<div id="mainCcontainer" ref={containerRef}>
					{showStarterContaine ? <Start musicEnabledRef={musicEnabledRef} containerRef={containerRef} onStart={handleStartGame} onMusicToggle={handleMusicToggle} /> : <Jeu musicEnabled={musicEnabledRef.current} containerRef={containerRef} />}
				</div>
			</header>
		</div>
	);
}

export default App;
